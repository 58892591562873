<template>
  <main class="contact_wrapper prvious_tickets_warpper">
    <div class="page-head">
      <div class="container">
        <router-link to="/">{{
          lang == "en" ? "Home" : "الرئيسية"
        }}</router-link>
        <i
          :class="
            lang == 'en'
              ? 'fa-solid fa-chevron-right'
              : 'fa-solid fa-chevron-left'
          "
        ></i>
        {{ lang == "en" ? "New Ticket" : "اتصل بنا" }}
      </div>
    </div>
    <div class="container">
      <div class="head">
        <h1>{{ lang == "en" ? "New Ticket" : "اتصل بنا" }}</h1>
        <p>
          {{
            lang == "en"
              ? "Our Team Would love to hear from you !"
              : "فريقنا يحب أن يسمع منك!"
          }}
        </p>
        <hr />
      </div>
      <div class="body">
        <form @submit.prevent>
          <input
            type="text"
            name="username"
            id="username"
            :placeholder="lang == 'en' ? 'Name' : 'الاسم'"
            v-model="username"
          />
          <input
            type="email"
            name="email"
            id="email"
            :placeholder="lang == 'en' ? 'Email' : 'الايميل'"
            v-model="email"
          />
          <input
            type="text"
            name="subject"
            id="subject"
            :placeholder="lang == 'en' ? 'Subject' : 'الموضوع'"
            v-model="subject"
          />
          <select
            name="per_pag"
            id="per_page"
            class="select-design"
            v-model="selectedReason"
            @change="fetchOrders()"
          >
            <option value="" selected>{{ lang == 'en' ? 'Select Reason' : 'اختر السبب'}}</option>
            <option
              v-for="reason in reasons"
              :key="reason.id"
              :value="reason.id"
            >
              {{ reason.name }}
            </option>
          </select>
          <select
            v-if="selectedReason == 2 && isLoggedIn"
            name="per_pag"
            id="per_page"
            class="select-design"
            v-model="orderNumber"
          >
            <option value="" selected>Select Order #</option>
            <option
              v-for="order in ordersDetail"
              :key="order.id"
              :value="order.id"
            >
              {{ order.name }}
            </option>
          </select>
          <input
            v-else-if="selectedReason == 2"
            type="text"
            name="username"
            id="username"
            placeholder="Type order #"
            v-model="orderNumber"
          />
          <textarea
            name="msg"
            id="msg"
            cols="30"
            rows="10"
            :placeholder="lang == 'en' ? 'Message' : 'محتوى الرسالة'"
            v-model="msg"
          ></textarea>

          <div class="captcha-container">
            <vue-recaptcha
              sitekey="6Lciwh8qAAAAAD3ys8SMzrriLtLza2WrqGtVAZvj"
              size="normal"
              theme="light"
              :loading-timeout="loadingTimeout"
              @verify="recaptchaVerified"
              @expire="recaptchaExpired"
              @fail="recaptchaFailed"
              @error="recaptchaError"
              ref="vueRecaptcha"
            >
            </vue-recaptcha>
          </div>
          <input
            type="file"
            class="file-attach"
            accept=".jpg, .png"
            multiple
            @change="selectFile"
          />

          <button @click="createTicket()">
            {{ lang == "en" ? "Send Message" : "أرسل رسالة" }}
          </button>
        </form>
        <div v-if="isLoggedIn">
          <h4>Previous Tickets</h4>
          <div class="table_wrapper">
            <table>
              <thead>
                <tr>
                  <td>{{ lang == "en" ? "Ticket #" : "الطلب" }}</td>
                  <td>{{ lang == "en" ? "Order #" : "وسيلة الدفع" }}</td>
                  <td>{{ lang == "en" ? "Created At" : "التاريخ" }}</td>
                  <td>{{ lang == "en" ? "Status" : "المجموع" }}</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="ticket in previousTickets"
                  :key="ticket.ticket_number"
                >
                  <td>{{ ticket?.id }}</td>
                  <td>{{ ticket.order_id }}</td>
                  <td>{{ formatDate(ticket.created_at) }}</td>
                  <td>
                    <span>{{ ticket.status }}</span>
                    <a
                      :href="`ticket-conversation/${ticket.uuid}`"
                      target="_blank"
                      ><i
                        class="fa fa-external-link open-ticket"
                        aria-hidden="true"
                      ></i
                    ></a>
                  </td>
                </tr>
                <tr v-if="!previousTickets.length" colspan="3">
                  <td v-if=" lang == 'en'">There is no ticket!</td>
                  <td v-else>لا يوجد طلبات للدعم</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55251.33663489036!2d31.21726479293497!3d30.059556316659574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583fa60b21beeb%3A0x79dfb296e8423bba!2sCairo%2C%20Cairo%20Governorate!5e0!3m2!1sen!2seg!4v1691388418277!5m2!1sen!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
      </div>
    </div>
    <section class="contact-info">
      <div class="container">
        <div>
          <h1>
            {{ lang == "en" ? "WE'd Love To" : "نحن سعداء" }} <br />
            {{ lang == "en" ? "Hear Form You" : "لتلقي رسالتك" }}
          </h1>
          <p>
            {{
              lang == "en"
                ? "Chat with our friendly team"
                : "الدردشة مع فريقنا الودود"
            }}
          </p>
        </div>
        <div>
          <i class="fa fa-envelope"></i>
          <h3>{{ lang == "en" ? "Email Us" : "راسلنا" }}</h3>
          <a href="mailto:info@egyptgamestore.com"> info@egyptgamestore.com </a>
        </div>
        <div>
          <i class="fa fa-phone"></i>
          <h3>{{ lang == "en" ? "Call Us" : "اتصل بنا" }}</h3>
          <p>
            {{ lang == "en" ? "Sunday to Thursday" : "من الاحد الي الخميس" }}
            <br />
            {{ lang == "en" ? "from 9 am to 10 pm" : "من ٩ صباحا حتى ١٠ مساء" }}
          </p>
          <a href="tel:00201145636999"> 00201145636999 </a>
        </div>
        <div>
          <i class="fa-solid fa-landmark"></i>
          <h3>{{ lang == "en" ? "Visit Us" : "نتشرف بزيارتك" }}</h3>
          <p>
            {{
              lang == "en"
                ? "10 Mashroe Kolleyet El Banat, Naser city, Cairo Egypt"
                : "10 مشروع كلية البنات, مدينة نصر, القاهرة مصر"
            }}
          </p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;
import vueRecaptcha from "vue3-recaptcha2";
import axios from "axios";

export default {
  name: "ContactView",
  data() {
    return {
      username: null,
      last_name: null,
      email: null,
      subject: null,
      msg: null,
      lang: "en",
      previousTickets: [
        {
          ticket_number: 123,
          order_number: 456,
          created_at: "3 July 2024",
          status: "pending",
        },
      ],
      isLoggedIn: sessionStorage.getItem("user")
        ? sessionStorage.getItem("user")
        : false,
      selectedReason: "",
      reasons: [],
      orderNumber: "",
      ordersDetail: [],
      selectFiles: [],
    };
  },
  components: {
    vueRecaptcha,
  },
  methods: {
    setLangCookies() {
      let langCheck = document.cookie.indexOf("lang");

      this.is_cookies = langCheck >= 0 ? true : false;

      function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      } // to get an cookie by name ##############################

      if (langCheck !== -1) {
        this.lang = getCookie("lang"); // check lang cookie exist ##############################
      }

      if (sessionStorage.getItem("lang"))
        this.lang = sessionStorage.getItem("lang"); // check lang session exist ##############################

      sessionStorage.setItem("lang", this.lang); // set lang session ##############################

      let searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("lang")) {
        this.lang = searchParams.get("lang");
        document.body.classList.add(searchParams.get("lang")); // add lang class ##############################
      } else {
        document.body.classList.add(this.lang); // add lang class ##############################
      }
    },
    async createTicket() {
      $(".loader").fadeIn().css("display", "flex");
      const formData = new FormData();
      formData.append("name", this.username);
      formData.append("email", this.email);
      formData.append("subject", this.subject);
      formData.append("message", this.msg);
      formData.append("reason_id", this.selectedReason);
      formData.append("order_id", this.orderNumber);
      for (let i = 0; i < this.selectFiles.length; i++) {
        formData.append("images[]", this.selectFiles[i]);
      }

      try {
        const response = await axios.post(
          `${window.main_url}/tickets`,
          formData,
          {
            headers: {
              AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
              lang: this.lang,
            },
          }
        );
        if (response.data.status === true) {
          document.getElementById("errors").innerHTML = "";
          let error = document.createElement("div");
          error.classList = "success";
          error.innerHTML = response.data.message;
          document.getElementById("errors").append(error);
          $("#errors").fadeIn("slow");
          $(".body input, .body textarea").val("");
          setTimeout(() => {
            $("#errors").fadeOut("slow");
            $(".loader").fadeOut();
          }, 2000);
          this.getPreviousTickets();
          this.username = null;
          this.subject = null;
          this.msg = null;
          this.selectedReason = "";
          this.orderNumber = "";

          this.selectFiles = [];
        } else {
          $(".loader").fadeOut();
          document.getElementById("errors").innerHTML = "";
          $.each(response.data.errors, function (key, value) {
            let error = document.createElement("div");
            error.classList = "error";
            error.innerHTML = value;
            document.getElementById("errors").append(error);
          });
          $("#errors").fadeIn("slow");

          setTimeout(() => {
            $("input").css("outline", "none");
            $("#errors").fadeOut("slow");
          }, 3500);
        }
      } catch (error) {
        document.getElementById("errors").innerHTML = "";
        let err = document.createElement("div");
        err.classList = "error";
        err.innerHTML = "server error try again later";
        document.getElementById("errors").append(err);
        $("#errors").fadeIn("slow");
        $(".loader").fadeOut();

        setTimeout(() => {
          $("#errors").fadeOut("slow");
        }, 3500);

        console.error(error);
      }
    },
    async getReasons() {
      $(".loader").fadeIn().css("display", "flex");
      try {
        const response = await axios.get(
          `https://api.egyptgamestore.com/api/reasons`,
          {
            headers: {
              lang: this.lang,
            },
          }
        );
        if (response.data.status === true) {
          this.reasons = response.data.data;

          const orderNumber = this.$route?.query?.order
          if (orderNumber) {
            const orderRelatedReason = this.reasons.find(reason => reason.show_orders)
            if (orderRelatedReason) {
              this.selectedReason = orderRelatedReason.id

              this.fetchOrders();
            }
          }
          $(".loader").fadeOut();
        } else {
          $(".loader").fadeOut();
          document.getElementById("errors").innerHTML = "";
          $.each(response.data.errors, function (key, value) {
            let error = document.createElement("div");
            error.classList = "error";
            error.innerHTML = value;
            document.getElementById("errors").append(error);
          });
          $("#errors").fadeIn("slow");

          setTimeout(() => {
            $("input").css("outline", "none");
            $("#errors").fadeOut("slow");
          }, 3500);
        }
      } catch (error) {
        document.getElementById("errors").innerHTML = "";
        let err = document.createElement("div");
        err.classList = "error";
        err.innerHTML = "server error try again later";
        document.getElementById("errors").append(err);
        $("#errors").fadeIn("slow");
        $(".loader").fadeOut();

        setTimeout(() => {
          $("#errors").fadeOut("slow");
        }, 3500);

        console.error(error);
      }
    },
    getHomeData() {
      this.setLangCookies();
    },
    async fetchOrders() {
      if (!(this.isLoggedIn && this.selectedReason == 2)) {
        return;
      }
      $(".loader").fadeIn().css("display", "flex");
      try {
        const response = await axios.get(
          "https://api.egyptgamestore.com/api/users/orders/pagination?status=all&per_page=20000&page=1",
          {
            headers: {
              AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
              lang: this.lang,
            },
          }
        );
        if (response.data.status === true) {
          this.ordersDetail = response.data.data.orders;
          const orderNumber = this.$route?.query?.order
          if (orderNumber) {
            const orderDetail = this.ordersDetail.find(order => order.id == orderNumber)
            if (orderDetail) {
              this.orderNumber = orderDetail.id
            }
          }
          $(".loader").fadeOut();
        } else {
          $(".loader").fadeOut();
          document.getElementById("errors").innerHTML = "";
          $.each(response.data.errors, function (key, value) {
            let error = document.createElement("div");
            error.classList = "error";
            error.innerHTML = value;
            document.getElementById("errors").append(error);
          });
          $("#errors").fadeIn("slow");

          setTimeout(() => {
            $("input").css("outline", "none");
            $("#errors").fadeOut("slow");
          }, 3500);
        }
      } catch (error) {
        document.getElementById("errors").innerHTML = "";
        let err = document.createElement("div");
        err.classList = "error";
        err.innerHTML = "server error try again later";
        document.getElementById("errors").append(err);
        $("#errors").fadeIn("slow");
        $(".loader").fadeOut();

        setTimeout(() => {
          $("#errors").fadeOut("slow");
        }, 3500);

        console.error(error);
      }
    },
    selectFile(event) {
      console.log("target", event);
      const files = event.target.files;
      this.selectFiles = files;
    },

    async getPreviousTickets() {
      if (!this.isLoggedIn) {
        return;
      }
      $(".loader").fadeIn().css("display", "flex");
      try {
        const response = await axios.get(`${window.main_url}/tickets`, {
          headers: {
            AUTHORIZATION: "Bearer " + sessionStorage.getItem("user_token"),
            lang: this.lang,
          },
        });
        if (response.data.status === true) {
          this.previousTickets = response.data.data.tickets;
          $(".loader").fadeOut();
        } else {
          $(".loader").fadeOut();
          document.getElementById("errors").innerHTML = "";
          $.each(response.data.errors, function (key, value) {
            let error = document.createElement("div");
            error.classList = "error";
            error.innerHTML = value;
            document.getElementById("errors").append(error);
          });
          $("#errors").fadeIn("slow");

          setTimeout(() => {
            $("input").css("outline", "none");
            $("#errors").fadeOut("slow");
          }, 3500);
        }
      } catch (error) {
        document.getElementById("errors").innerHTML = "";
        let err = document.createElement("div");
        err.classList = "error";
        err.innerHTML = "server error try again later";
        document.getElementById("errors").append(err);
        $("#errors").fadeIn("slow");
        $(".loader").fadeOut();

        setTimeout(() => {
          $("#errors").fadeOut("slow");
        }, 3500);

        console.error(error);
      }
    },
    /**
     * Formats a date string into the format dd/mm/yyyy.
     *
     * @param {string} dateString - The date string to be formatted.
     * @returns {string} The formatted date string or the original input if an error occurs.
     */
    formatDate(dateString) {
      try {
        // Create a new Date object from the date string
        const date = new Date(dateString);

        // Get the day, month, and year from the Date object
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
        const year = date.getFullYear();

        // Format the date in dd/mm/yyyy
        return `${day}/${month}/${year}`;
      } catch (err) {
        return dateString;
      }
    },
  },
  created() {
    this.getHomeData();
    const user = JSON.parse(sessionStorage.getItem("user"));
    console.log("user =>>>>>", user);
    if (user?.email) {
      this.email = user.email;
    }
    this.getPreviousTickets();
    this.getReasons();
  },
};
</script>

<style scoped>
@import "./../assets/css/home.css";

.file-attach {
  /* margin-left: auto; */
  max-width: 210px;
  width: 100%;
  grid-column: span 2 !important;
  margin-left: 2em;
  height: 44px;
}
.table_wrapper {
  margin-top: 8px;
}
.captcha-container {
  grid-column: span 2;
}
@media (max-width: 1240px) {
  .captcha-container {
    grid-column: span 4;
  }
  .file-attach {
    grid-column: span 4 !important;
    margin-left: 0em !important;
  }
}
.select-design {
  grid-column: span 2 !important;
  border: 1px solid #d5dfe4;
  border-radius: 0.3rem;
  padding: 0.65rem 1rem;
  color: #0b5177;
}
.open-ticket {
  font-size: smaller;
  margin-left: 4px;
}
</style>
